import React from 'react';
import { Table, Button } from 'antd';
import axios from 'axios';import 
{ EditOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';


interface User {
    id: string;
    key: string;
    name: string;
    mail: string;
    status: string;
}

interface UserTableProps {
    data: User[];
    onEdit: (key: string) => void;
}

const UserTable: React.FC<UserTableProps> = ({ data, onEdit }) => {
    

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mail',
            dataIndex: 'email',
            key: 'mail',
        },
        {
            title: 'Status',
            dataIndex: 'status_id',
            key: 'status',
            render: (status_id: string) => {
                return status_id == '1' ? 
                    <div style={{ background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>{'ACTIVE'}</div> 
                    : <div style={{ background: `#D201031A`, color: '#D20103', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>{'INACTIVE'}</div>;
            }
        },
        {
            title: 'Loads',
            dataIndex: 'loadCount',
            key: 'loadCount',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: User) => (
                <Button  color="primary" type="dashed" onClick={() => onEdit(record.id)} >Detail</Button>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data} rowKey="key" />;
};

export default UserTable;