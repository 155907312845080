import React, { useEffect, useState } from 'react';
import { notification, Steps, Card, Row, Col, Typography, Tag, Timeline, Divider, Space, Collapse, Empty, Input, Button, Select } from 'antd';
import { FilePdfTwoTone, LoadingOutlined, SmileOutlined, FileDoneOutlined, HistoryOutlined } from '@ant-design/icons';
import { lightTheme, darkTheme } from '../../Layout/theme';
import { ConfigProvider } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { format, set } from 'date-fns';
import FileUpload from './FileUpload';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import hasPermission from '../../Hooks/hasPermission';

const { Text } = Typography;
const url = process.env.REACT_APP_API_URL;

const GuideDetail: React.FC = () => {
  const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
  const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
  const users = localStorage.getItem('users');
  const user = users ? JSON.parse(users)[0] : false;
  const { id } = useParams<{ id: string }>();
  const [loadDetails, setLoadDetails] = useState<any>({});
  const [editorContent, setEditorContent] = useState('');
  const [dyloReference, setDyloReference] = useState('');
  const [fullfillment, setFullfillment] = useState('');
  const [clientReference, setClientReference] = useState('');
  const [statusData, setStatusData] = useState<any[]>([]);
  const [actualStautus, setActualStatus] = useState();
  const [clientList, setClientList] = useState<any[]>([]);
  const [selecetdClient, setSelectedClient] = useState<any>();
  const [deliveriesList, setDeliveriesList] = useState<any[]>([]);
  const [selecetdDelivery, setSelectedDelivery] = useState<any>();
  const [deliveriesShippers, setDeliveriesShippers] = useState<any[]>([]);
  const [deliveriesConsigee, setDeliveriesConsigee] = useState<any[]>([]);
  const [deliveryShipperSelected, setDeliveryShipperSelected] = useState<any>();
  const [deliveryConsigeeSelected, setDeliveryConsigeeSelected] = useState<any>();
  const [deliveriesLoading, setDeliveriesLoading] = useState<boolean>(false);
  const navigate = useNavigate(); 

  const canViewLoadsAdmin = hasPermission(user?.permissionsActives,'LD_AD_VIEW'); 
  const canEditLoadsAdmin = hasPermission(user?.permissionsActives,'LD_AD_EDIT'); 
  const canEditLoads = hasPermission(user?.permissionsActives,'LD_EDIT'); 

  useEffect(() => {
    fetchLoadDetails();
    getClientsList();
  }, [id]); 

  const tokenExpired = () => {
    notification.warning({
      message: 'Access Failed',
      description: 'Expire or invalid credentials. Login again.',
      showProgress: true,
      pauseOnHover: true,
    });
    localStorage.removeItem('tokens');
    localStorage.removeItem('users');
    navigate('/login');
  }; 

  const errorRedirecciont = (error: any) => {
    if (error.response?.status === 404) {
      navigate('/reports');
    }
    if (error.response?.status === 401) {
      tokenExpired();
    }
    if (error.response?.status === 403) {
      navigate('/');
    }
  }

  const fetchLoadDetails = async () => {
    try {
      const response = await axios.get(`${url}/loads/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      await setLoadDetails(response.data);
      if (!response.data.glt_reference) {
        getStatusCards();
      }
      setActualStatus(response.data.status_id);
      setDeliveryConsigeeSelected(response.data.delivery_destination);
      setDeliveryShipperSelected(response.data.delivery_origin);
    } catch (error: any) {
      notification.error({
        message: error.message ? error.message : 'Error fetching data',
        description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
        placement: 'bottomRight',
      });
      errorRedirecciont(error);
      console.error('Error fetching load details:', error);
    }
  };

  const getStatusCards = async () => {
    try {
      const response = await axios.get(`${url}/status-loads`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      setStatusData(response.data);

    } catch (error: any) {
      notification.warning({
        message: error.response?.status,
        description: error.response?.message,
        showProgress: true,
        pauseOnHover: true,
      });
      errorRedirecciont(error);
      console.error('Error fetching status data:', error);
    }
  };

  const getClientsList = async () => {
    try{
      const response = await axios.get(`${url}/clients`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setClientList(response.data); 
    }catch (error: any) { 
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const updateReferences = async () => {
      await setDyloReference(loadDetails.dylo_reference);
      await setClientReference(loadDetails.client_reference);
      await setFullfillment(loadDetails.fullfillment);
      await setSelectedClient(loadDetails.client?.id);
      await setSelectedDelivery(loadDetails.delivery?.id);
    };
    updateReferences();
    if (selecetdClient || loadDetails.client?.id) {
      assignDeliveries()
    }
  }, [loadDetails]);

  const assignDeliveries = async () => {
    await getDeliveries();
    await setSelectedDelivery(loadDetails.delivery?.id);
  }

  const collapseLoadDetails = loadDetails.lineItems?.map((item: any, index: number) => (
    <div key={index}>
      <Divider orientation="left">  {item.name}</Divider>
      <p>Handling count: {item.handling_unit_count} ({item.handling_units})</p>
      <p>Dimensions (L x W x H): {item.length} x {item.width} x {item.height} ({item.dimension_units})</p>
      <p>Weight: {item.weight} ({item.weight_units})</p>
      <p>Description: {item.description}</p>
    </div>
  ));

  const renderJson = (json: any) => {
    if (typeof json !== 'object' || json === null) {
      return <span>{String(json)}</span>;
    }

    return (
      <ul>
        {Object.entries(json).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {renderJson(value)}
          </li>
        ))}
      </ul>
    );
  };

  const historyItems = loadDetails.histories
    ?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .map((history: any, index: number) => {
      let formattedJson;
      try {
        const jsonObject = JSON.parse(history.changes);
        formattedJson = jsonObject; // Mantener el objeto JSON
      } catch (error) {
        formattedJson = 'Invalid JSON string';
      }

      const formattedDate = format(new Date(history.createdAt), 'dd/MM/yyyy HH:mm');

      return {
        key: index,
        children: (
          <Card title={formattedDate} size='small'>
            {typeof formattedJson === 'string' ? (
              <pre>{formattedJson}</pre>
            ) : (
              renderJson(formattedJson)
            )}
          </Card>
        )
      };
    });
  const notesItems = loadDetails.notes?.slice().reverse().map((note: any, index: number) => {
    const formattedDate = format(new Date(note.createdAt), 'dd/MM/yyyy HH:mm');
    return {
      key: index,
      children: (
        <Card>
          <p>{formattedDate}</p>
          <div dangerouslySetInnerHTML={{ __html: note.note }} />
        </Card>
      )
    };
  });


  const getStepStatus = (statusId: number): ("finish" | "wait" | "process" | "error" | undefined)[] => {
    switch (statusId) {
      case 1: // Delivered
      case 3: // Completed
        return ['finish', 'finish', 'finish', 'finish'];
      // case 7: // Quotes received
      case 5: // Unassigned
        return ['process', 'wait', 'wait', 'wait'];
      case 2: // In transit
        return ['finish', 'finish', 'process', 'wait'];
      case 4: // assigned
        return ['finish', 'process', 'wait', 'wait'];
      default:
        return ['wait', 'wait', 'wait', 'wait'];
    }
  };

  const stepStatus = getStepStatus(loadDetails.status_id);

  const uploadNote = async () => {
    try {
      const response = await axios.post(`${url}/add-notes`, {
        load_id: id,
        content: editorContent,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
      setEditorContent('');
    } catch (error) {
      console.error('Error uploading note:', error);
    }
  }

  const updateReference = async (e: any, type: string) => {
    const value = e.target.value;
    if (type === 'Dylo') {
      setDyloReference(value);
    } else if (type === 'Client') {
      setClientReference(value);
    } else if (type === 'Fullfillment') {
      setFullfillment(value);
    }
    try {
      const response = await axios.post(`${url}/update-reference`, {
        load_id: id,
        reference: e.target.value,
        type: type,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
    } catch (error) {
      console.error('Error updating reference:', error);
    }
  }

  const updateStatus = async (status: any) => {
    setActualStatus(status);
    try {
      const response = await axios.post(`${url}/load/update-status`, {
        load_id: id,
        status_id: status,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  }

  const changeClient = async (value: any) => {
    setSelectedClient(value);
    updateClientDeliverie(value, 'Client_ID');
    changeDelivery(null, 'shipper');
    changeDelivery(null, 'consigee');
    setDeliveriesList([]);
  }

  const changeDelivery = async (value: any, role: 'shipper' | 'consigee') => {
    if (role === 'shipper') {
      setDeliveryShipperSelected(value);
      updateClientDeliverie(value, 'Delivery_Origin');
    }
    if (role === 'consigee') {
      setDeliveryConsigeeSelected(value);
      updateClientDeliverie(value, 'Delivery_Destination');
    } 

  }

  const getDeliveries = async () => {
    getDeliveriesShippers();
    getDeliveriesConsignee();
  }

  const getDeliveriesShippers = async () => {
    let client_id = selecetdClient ? selecetdClient : loadDetails.client?.id;
    setDeliveriesLoading(true);
    axios.get(`${url}/deliveries-list-by-role`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params: { client: client_id , role: 'shipper'}
    }).then((response) => {
      setDeliveriesShippers(response.data);
      setDeliveriesLoading(false);
    }).catch((error) => {
      console.error('Error fetching deliveries:', error);
      setDeliveriesLoading(false);
    });
  }

  const getDeliveriesConsignee = async () => {
    let client_id = selecetdClient ? selecetdClient : loadDetails.client?.id;
    setDeliveriesLoading(true);
    axios.get(`${url}/deliveries-list-by-role`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params: { client: client_id , role: 'consignee'}
    }).then((response) => {
      setDeliveriesConsigee(response.data);
      setDeliveriesLoading(false);
    }).catch((error) => {
      console.error('Error fetching deliveries:', error);
      setDeliveriesLoading(false);
    });
  }

  const updateClientDeliverie = async (reference: any, type: string) => {
    try {
      const response = await axios.post(`${url}/update-reference`, {
        load_id: id,
        reference: reference,
        type: type,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
    } catch (error : any) {
      
      notification.warning({
        message: error.response?.status,
        description:  error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
        showProgress: true,
        pauseOnHover: true,
      });
      errorRedirecciont(error);
      fetchLoadDetails();
      console.error('Error updating reference:', error);
    }


  }
  return (
    <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <div style={{ margin: '0 auto', paddingInline: '20px', paddingTop: '20px' }}>
        <div className="" style={{ display: 'flex' }}>
          <Card style={{ flex: 1, margin: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
            <div style={{ display: 'flex' }}>
              <Space align="baseline" size={100}>
                <div style={{ flex: 1 }}>
                  <h1>Pro number: {loadDetails.pro_number}</h1>
                </div>
                {/* <div style={{ flex: 1 }}>
                  {
                    loadDetails.pickup_carrier_number ? (
                      <h3>Pickup number: {loadDetails.pickup_carrier_number}</h3>
                    ) : null
                  }
                </div>
                <div style={{ flex: 1 }}>
                  {
                    loadDetails.pro_number ? (
                      <
                    h3>Pro number: {loadDetails.pro_number}</h3>
                    ) : null
                  } */}
                {/* <Link to={`https://www.sefl.com/webconnect/tracing?Type=PN&RefNum1=${loadDetails.pro_number}`} target="_blank">
                    <h3>Pro number: {loadDetails.pro_number}</h3>
                  </Link> */}
                {/* </div> */}
              </Space>
              <div style={{ background: '#87d0681A', color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                <h3 style={{ flex: 1, padding: '5px' }}
                >{( canEditLoads && !loadDetails.glt_reference && loadDetails.carrier_id !== '07afd7c8-706e-4790-bb57-9163f94c2df0')
                  ? <>
                    <Select variant="borderless" size='large' value={actualStautus} onChange={(value) => updateStatus(value)}>
                      {statusData.map((status: any, index: number) => (
                        <Select.Option key={index} value={status.id}>{status.name}</Select.Option>
                      ))}
                    </Select>
                  </> :
                  loadDetails.status?.name.toUpperCase()

                  }
                  {/* {
                    loadDetails.updatedAt && loadDetails.updatedAt !== '1970-01-01T00:00:00.000Z' ? (new Date(loadDetails.updatedAt).toLocaleDateString()) : null
                  } */}
                  {

                    (loadDetails.actual_delivery_date !== null && loadDetails.actual_pickup_date !== null) &&
                      (loadDetails.actual_delivery_date !== '1970-01-01T00:00:00.000Z' && loadDetails.actual_pickup_date !== '1970-01-01T00:00:00.000Z') ?
                      <> - Transit days: {Math.ceil((new Date(loadDetails.actual_delivery_date).getTime() - new Date(loadDetails.actual_pickup_date).getTime()) / (1000 * 60 * 60 * 24))}</> : null
                  }
                </h3>
              </div>
            </div>
          </Card>
        </div>
        <div style={{ margin: '0 auto', paddingInline: '20px' }}>
          <Steps
            items={[
              {
                title: 'Pending',
                status: stepStatus[0],
                icon: <HistoryOutlined />,
              },
              {
                title: 'Assigned',
                status: stepStatus[1],
                icon: <FileDoneOutlined />,
              },
              {
                title: 'In transit',
                status: stepStatus[2],
                icon: <LoadingOutlined />,
              },
              {
                title: 'Delivered',
                status: stepStatus[3],
                icon: <SmileOutlined />,
              },
            ]}
          />
        </div>
        <div className="" style={{ display: 'flex' }}>
          <div style={{ flex: 2, margin: 15 }}>
            <div style={{ display: 'flex' }}>
              <Card style={{ flex: 1, marginBottom: 15, marginRight: 7, textAlign: 'center', fontSize: '1.5em', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                { !loadDetails.deliveryOrigin ? (
                  <span> <strong> Origin: </strong> {loadDetails.origin} </span> 
                ) : (
                  <> 
                    <Collapse ghost>
                      <Collapse.Panel header={<span style={{ flex: 1,  textAlign: 'center', fontSize: '1.5em'}}> <strong> Origin: </strong> {loadDetails.origin} </span>} key="1">
                        <p> <strong> Shipper name: </strong> {loadDetails.deliveryOrigin.scac} - {loadDetails.deliveryOrigin.name}</p> 
                        <p> <strong> Street 1: </strong> {loadDetails.deliveryOrigin.street_one} </p>
                        <p> <strong> Street 2: </strong> {loadDetails.deliveryOrigin.street_two} </p>
                        <p> <strong> Colony: </strong> {loadDetails.deliveryOrigin.colony} </p> 
                        <p> <strong> Local number: </strong> {loadDetails.deliveryOrigin.local_number} </p> 
                        <p> <strong> Contact Main:</strong> {loadDetails.deliveryOrigin.contact_main} </p>
                        <p> <strong> Contact Warehouse:</strong> {loadDetails.deliveryOrigin.contact_warehouse} </p>
                        <p> <strong> Phone Main:</strong> {loadDetails.deliveryOrigin.phone} </p>
                      </Collapse.Panel>
                    </Collapse>
                  
                  </>
                ) }
              </Card>
              <Card style={{ flex: 1, marginBottom: 15, marginLeft: 7, textAlign: 'center', fontSize: '1.5em', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                { !loadDetails.deliveryDestination ? (
                  <span> <strong> Destination: </strong> {loadDetails.destiny} </span>
                ) : (
                  <> 
                    <Collapse ghost>
                      <Collapse.Panel header={<span style={{ flex: 1, textAlign: 'center', fontSize: '1.5em'}}> <strong> Destination: </strong> {loadDetails.destiny} </span>} key="1">
                        <p> <strong> Consignee name: </strong> {loadDetails.deliveryDestination.scac} - {loadDetails.deliveryDestination.name}</p> 
                        <p> <strong> Street 1: </strong> {loadDetails.deliveryDestination.street_one} </p>
                        <p> <strong> Street 2: </strong> {loadDetails.deliveryDestination.street_two} </p>
                        <p> <strong> Colony: </strong> {loadDetails.deliveryDestination.colony} </p> 
                        <p> <strong> Local number: </strong> {loadDetails.deliveryDestination.local_number} </p> 
                        <p> <strong> Contact Main:</strong> {loadDetails.deliveryDestination.contact_main} </p>
                        <p> <strong> Contact Warehouse:</strong> {loadDetails.deliveryDestination.contact_warehouse} </p>
                        <p> <strong> Phone Main:</strong> {loadDetails.deliveryDestination.phone} </p>
                      </Collapse.Panel>
                    </Collapse>
                  
                  </>
                ) }
              </Card>
            </div>
            <div style={{ display: 'flex' }}>
              <Card style={{ flex: 1, padding: '20px', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, textAlign: 'center', fontSize: '1.4em' }}>
                    <Row gutter={[16, 16]}>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Carrier:</strong> <Text code type='danger' style={{ fontSize: '1em' }}>{loadDetails.carrier?.name}</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        {
                          loadDetails.actual_delivery_date ?
                            <>
                              <strong>Delivery Date :</strong>
                              <Text code type='danger' style={{ fontSize: '1em' }}>
                                {loadDetails.actual_delivery_date !== '1970-01-01T00:00:00.000Z' ? (new Date(loadDetails.actual_delivery_date).toLocaleDateString()) : null}
                              </Text>
                            </>
                            : (<>
                              <strong>Expected Delivery Date :</strong>
                              <Text code type='danger' style={{ fontSize: '1em' }}>
                                {loadDetails.expected_delivery_date !== '1970-01-01T00:00:00.000Z' ? (new Date(loadDetails.expected_delivery_date).toLocaleDateString()) : null}
                              </Text>
                            </>
                            )
                        }
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Mode:</strong> {loadDetails.mode_name}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Dispatcher:</strong> {loadDetails.dispatcher_name}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Billing Reference:</strong> {loadDetails.billing_reference}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>PO Number:</strong> {loadDetails.po_number}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Reference Client:</strong>

                        {
                          canEditLoads ? (<>
                            <Input
                              value={clientReference}
                              onChange={(e) => setClientReference(e.target.value)}
                              onBlur={(e) => { updateReference(e, 'Client') }}
                            />

                          </>) :
                            <>{clientReference}</>}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Reference Dylo:</strong>
                        {
                          canEditLoads ? (<>
                            <Input
                              value={dyloReference}
                              onChange={(e) => setDyloReference(e.target.value)}
                              onBlur={(e) => { updateReference(e, 'Dylo') }}
                            />

                          </>) :
                            <>{dyloReference}</>}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Fullfillment:</strong>
                        {
                          canEditLoads ? (<>
                            <Input
                              value={fullfillment}
                              onChange={(e) => setFullfillment(e.target.value)}
                              onBlur={(e) => { updateReference(e, 'Fullfillment') }}
                            />

                          </>) :
                            <>{fullfillment}</>}
                      </Col> 
                      {
                        canEditLoadsAdmin ?
                          <>
                            <Divider> Client / Delivery </Divider>
                            <Col span={8} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                              <strong>Client:</strong>
                              <Select style={{ width: '100%' }} value={selecetdClient} onChange={(value) => { changeClient(value) }} allowClear onClear={() => { changeClient(null) }}>
                                {clientList.map((client: any, index: number) => (
                                  <Select.Option key={index} value={client.id}>{client.name}</Select.Option>
                                ))}
                              </Select>
                            </Col>

                            <Col span={8} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                              <strong>Delivery shipper:</strong>
                              <Select disabled={!selecetdClient || deliveriesLoading} 
                                      style={{ width: '100%' }} 
                                      value={deliveryShipperSelected} 
                                      onChange={(value) => { changeDelivery(value,'shipper') }} 
                                      loading={deliveriesLoading} 
                                      onClear={() => { changeDelivery(null,'shipper') }}
                                      allowClear 
                                      showSearch
                                      filterOption={(input, option) =>
                                        typeof option?.children === 'string' ? (option.children as string).toLowerCase().includes(input.toLowerCase()) : false
                                        }>
                                {deliveriesShippers.map((delivery: any, index: number) => (
                                  <Select.Option key={index} value={delivery.id}>{delivery.scac + ' - ' + delivery.name}</Select.Option>
                                ))}
                              </Select>
                            </Col>
                            <Col span={8} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                              <strong>Delivery consignee:</strong>
                              <Select disabled={!selecetdClient || deliveriesLoading} 
                                  style={{ width: '100%' }} 
                                  value={deliveryConsigeeSelected} 
                                  onChange={(value) => { changeDelivery(value,'consigee') }} 
                                  loading={deliveriesLoading}
                                  onClear={() => { changeDelivery(null,'consigee') }}
                                  optionFilterProp="children"
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                  typeof option?.children === 'string' ? (option.children as string).toLowerCase().includes(input.toLowerCase()) : false
                                  }>
                              {deliveriesConsigee.map((delivery: any, index: number) => (
                                <Select.Option key={index} value={delivery.id}>{delivery.scac + ' - ' + delivery.name}</Select.Option>
                              ))}
                              </Select>
                            </Col>
                          </> : null
                      }
                    </Row>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div style={{ flex: 1, margin: 15 }}>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                <Collapse
                  ghost
                  items={[
                    {
                      key: '1',
                      label: 'Documents',
                      children: (
                        <>
                          {loadDetails.document?.length > 0 ? (
                            <Space direction="vertical" size="middle" style={{ display: 'flex', marginBottom: 15 }}>
                              {loadDetails.document?.map((doc: any, index: number) => (
                                <p key={index}>
                                  <FilePdfTwoTone twoToneColor="#eb2f96" style={{ marginRight: '1em', marginLeft: '1em' }} />
                                  <Link to={doc.file} target="_blank">
                                    <Text italic>{doc.name}</Text>
                                  </Link>
                                </p>
                              ))}
                            </Space>) : (<Empty />)}
                          {
                            canEditLoads ? (<>
                              <Divider > </Divider>
                              <FileUpload id={loadDetails.id} fetchLoadDetails={fetchLoadDetails} />
                            </>) : null}
                        </>
                      ),
                    },
                  ]}
                />
              </Card>
            </div>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                <Collapse ghost items={[{ key: 'Load-Details-1', label: 'Load Details', children: collapseLoadDetails }]} />
              </Card>
            </div>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                <Collapse ghost items={[{
                  key: 'Accessorials-1', label: 'Accessorials', children:
                    (<>
                      {
                        loadDetails.accessorial?.length > 0 ? (
                          <div >
                            {loadDetails.accessorial?.map((acc: any, index: number) => (
                              <p key={index}>
                                <Divider orientation="left">Accesorial {acc.name}</Divider>
                                <p>Accesorial: {acc.FullName}</p>
                                <p>Type: {acc.accessorial_type}</p>
                              </p>
                            ))}
                          </div>
                        ) : (<Empty />)}
                    </>)
                }]} />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '0 auto', paddingLeft: '20px', flex: 1 }}>
          <Divider orientation="left">Notes</Divider>
          <Card style={{ flex: 1, margin: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
            {
              canEditLoads ? (<>
                <Divider orientation="left">Add note</Divider>
                <ReactQuill theme="snow" value={editorContent} onChange={setEditorContent} style={{ borderBlock: 'none' }} />
                <Button type="primary" block onClick={uploadNote}> Add note </Button>
              </>) : null
            }

            <Divider orientation="left" style={{ paddingTop: 10 }}>History notes</Divider>
            {loadDetails.notes?.length > 0 ? <Timeline items={notesItems}></Timeline> : <Empty />}

          </Card>
        </div>
        { canViewLoadsAdmin && (
          <div style={{ margin: '0 auto', paddingRight: '20px', paddingLeft: '10px', flex: 1 }}>
            <Divider orientation="left">History</Divider>
            <Card style={{ flex: 1, margin: 15, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
              {loadDetails.histories?.length > 0 ? <Timeline items={historyItems}></Timeline> : <Empty />}
            </Card>
          </div>)

        }

      </div>
    </ConfigProvider>
  );
};

export default GuideDetail;