import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Input, Row, Select, Space, Spin, Tooltip } from 'antd';
import type { DatePickerProps, SelectProps } from 'antd';
import { FileExcelTwoTone } from '@ant-design/icons';
import axios from 'axios';
import hasPermission from '../../Hooks/hasPermission';
const { Search } = Input;

interface ReportsFiltersProps {
    datePickerValue: any;
    setDatePickerValue: (value: any) => void;
    selectedCarriers: string[];
    setSelectedCarriers: (value: string[]) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    loading: boolean;
    generateReport: () => void;
    clientList: any;
    deliveriesList: any;
    selecetdClients: string[];
    setSelectedClients: (value: string[]) => void;
    selectedDeliveries: string[];
    setSelectedDeliveries: (value: string[]) => void;

}

interface Carrier {
    name: string;
    id: string;
}

const ReportsFilters: React.FC<ReportsFiltersProps> = ({
    datePickerValue,
    setDatePickerValue,
    selectedCarriers,
    setSelectedCarriers,
    searchValue,
    setSearchValue,
    loading,
    generateReport,
    clientList,
    deliveriesList,
    selecetdClients,
    setSelectedClients,
    selectedDeliveries,
    setSelectedDeliveries
}) => {
    const url = process.env.REACT_APP_API_URL;
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];

    const users = localStorage.getItem('users');
    const user = users ? JSON.parse(users)[0] : false;
    const canClients = hasPermission(user?.permissionsActives, 'CLIENTS');
    const canDeliveries = hasPermission(user?.permissionsActives, 'DELIVERIES');

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await axios.get(`${url}/carriers`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`
                        }
                    }
                );
                setCarriers(response.data);
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };
        fetchCarriers();
    }, []);

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (date) {
            setDatePickerValue(date);
        } else {
            setDatePickerValue(null);
        }
    };

    const carrierOptions = carriers.map((carrier: Carrier) => ({
        label: carrier.name,
        value: carrier.id
    }));

    const sharedProps: SelectProps = {
        mode: 'multiple',
        style: { width: '200px' },
        maxTagCount: 'responsive'
    };

    const carrierSelectProps: SelectProps = {
        ...sharedProps,
        options: carrierOptions,
        value: selectedCarriers,
        onChange: setSelectedCarriers,
        placeholder: 'Select Carrier/s'
    };

    const clientSelectProps: SelectProps = {
        ...sharedProps,
        options: clientList.map((client: any) => ({ label: client.name, value: client.id })),
        value: selecetdClients,
        onChange: setSelectedClients,
        placeholder: 'Select Client/s'
    };

    const deliverySelectProps: SelectProps = {
        ...sharedProps,
        options: deliveriesList.map((delivery: any) => ({ label: delivery.name, value: delivery.id })),
        value: selectedDeliveries,
        onChange: setSelectedDeliveries,
        placeholder: 'Select Delivery/s'
    };

    return (
        <Spin spinning={loading}>
            <div style={{ marginRight: '25px', marginLeft: '25px' }}>
                <Card style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <Row gutter={16}>
                        <Col span={1}>
                            <Tooltip placement="top" title={'Generate Report'}>
                                <Button onClick={generateReport} color='primary' type="text" icon={<FileExcelTwoTone twoToneColor="#2B9704" />} size={'large'} />
                            </Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip placement="top" title={'Filter by Month'}>
                                <DatePicker
                                    onChange={onChange}
                                    picker="month"
                                    value={datePickerValue}
                                    disabled={loading}
                                    style={{ width: '100%' }}
                                />
                            </Tooltip>
                        </Col>
                        <Col span={5}>

                            <Tooltip placement="top" title={'Filter by Carrier/s'}>
                                <Select {...carrierSelectProps} disabled={loading} style={{ width: '100%' }} />
                            </Tooltip>
                        </Col>
                        <Col span={5}>
                            {canClients && (
                                <>

                                    <Tooltip placement="top" title={'Filter by Client/s'}>
                                        <Select {...clientSelectProps} disabled={loading} style={{ width: '100%' }} />
                                    </Tooltip>
                                </>
                            )}
                        </Col>
                        <Col span={5}>
                            {canDeliveries && (
                                <>
                                    <Tooltip placement="top" title={'Filter by Delivery/s'}>
                                        <Select {...deliverySelectProps} disabled={loading}
                                            style={{ width: '100%' }} />
                                    </Tooltip>
                                </>
                            )}

                        </Col>
                        <Col span={4}>
                            <Tooltip placement="top" title={'Search by text'}> 
                                <Search
                                    placeholder="Search"
                                    value={searchValue}
                                    disabled={loading}
                                    onChange={e => setSearchValue(e.target.value)}
                                    onSearch={value => console.log(value)}
                                    style={{ width: '100%' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Spin>
    );
};

export default ReportsFilters;