import React from 'react';
import { Select, Input, Button } from 'antd';
import {UserAddOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;

interface DeliveriesFiltersProps {
    onSearch: (value: string) => void;
    onCreate: () => void;
}

const DeliveriesFilters: React.FC<DeliveriesFiltersProps> = ({ onSearch, onCreate }) => {
    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
        onSearch(event.target.value);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button 
                icon={React.createElement(UserAddOutlined)}
                type="primary" 
                style={{ minWidth: 200 }}
                onBlur={handleBlur}
                onClick={onCreate}
            > 
                Create Deliverie
            </Button>

            <Select
                placeholder="Select Client"
                style={{ minWidth: 200 }}
                onBlur={handleBlur}
            >
                <Option value="client1">Client 1</Option>
                <Option value="client2">Client 2</Option> 
            </Select> 

            <Search
                placeholder="Search"
                onBlur={handleBlur}
                style={{ width: '30%' }}
            />
        </div>
    );
};

export default DeliveriesFilters;