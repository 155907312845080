// Routing.tsx
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home';
import Login from '../Pages/Auth/Login';
import Dashboard from '../Pages/Dashboard';
import Reports from '../Pages/Reports/Reports';
import GuideDetail from '../Pages/GuideDetail/GuideDetail';
import MainLayout from '../Layout/MainLayout';
import { ConfigProvider } from 'antd';
import { lightTheme, darkTheme } from '../Layout/theme';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import LoadPublic from '../Pages/LoadPublic/Search';
import DetailPublic from '../Pages/LoadPublic/DetailPublic';
import CreateLoad from '../Pages/CreateLoad/CreateLoad';
import Users from '../Pages/Users/Users';
import UserCreate from '../Pages/CreateUser/CreateUser';
import ClientList from '../Pages/Clients/ClientList';
import DeliveriesList from '../Pages/Deliveries/DeliveriesList';
import ClientDetail from '../Pages/Clients/ClientDetail';
import DeliveriresCreate from '../Pages/Deliveries/DeliveriresCreate';
import DeliveriesDetails from '../Pages/Deliveries/DeliveriesDetails';
import UserDetails from '../Pages/Users/UserDetails';

const AppRoutes: React.FC = () => {
	const [collapsed, setCollapsed] = useState(true);
	const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
	const currentTheme = isDarkMode ? lightTheme : lightTheme;
	const users = localStorage.getItem('users');
	const user = users ? JSON.parse(users)[0] : false;

	return (
		<AuthProvider>
			<Routes>
				<Route
					path="/login"
					element={
						<ConfigProvider theme={currentTheme}>
							<Login />
						</ConfigProvider>
					}
				/>
				<Route
					path="/find-load"
					element={
						<ConfigProvider theme={currentTheme}>
							<LoadPublic />
						</ConfigProvider>
					}
				/>
				<Route
					path="/public-load"
					element={
						<ConfigProvider theme={currentTheme}>
							<DetailPublic />
						</ConfigProvider>
					}
				/>
				<Route
					path="*"
					element={
						<MainLayout collapsed={collapsed} setCollapsed={setCollapsed}>
							<div style={{ minHeight: '90vh', height: '100%' }}>
								<Routes>
									<Route path="/" element={<PrivateRoute>
										<Home />
									</PrivateRoute>} />
									<Route
										path="/dashboard"
										element={
											<PrivateRoute>
												<Dashboard />
											</PrivateRoute>
										}
									/>
									<Route
										path="/reports"
										element={
											<PrivateRoute>
												<Reports />
											</PrivateRoute>
										}
									/>
									<Route
										path="/guide/detail/:id"
										element={
											<PrivateRoute>
												<GuideDetail />
											</PrivateRoute>
										}
									/> 
									<Route
										path="/new-load"
										element={
											<PrivateRoute>
												<CreateLoad />
											</PrivateRoute>
										}
									/>
									<Route
										path="/users"
										element={
											<PrivateRoute>
												<Users />
											</PrivateRoute>
										}
									/>
									<Route
										path="/user-create"
										element={
											<PrivateRoute>
												<UserCreate />
											</PrivateRoute>
										}
									/>
									<Route
										path="/user-detail/:id"
										element={
											<PrivateRoute>
												<UserDetails />
											</PrivateRoute>
										}
									/>
									<Route
										path="/clients"
										element={
											<PrivateRoute>
												<ClientList />
											</PrivateRoute>
										}
									/>
									<Route
										path="/deliveries"
										element={
											<PrivateRoute>
												<DeliveriesList />
											</PrivateRoute>
										}
									/>
									<Route
										path="/deliveries/create"
										element={
											<PrivateRoute>
												<DeliveriresCreate />
											</PrivateRoute>
										}
									/>
									<Route
										path="/deliveries/details/:id"
										element={
											<PrivateRoute>
												<DeliveriesDetails />
											</PrivateRoute>
										}
									/>
									<Route
										path="/client/:id"
										element={
											<PrivateRoute>
												<ClientDetail />
											</PrivateRoute>
										}
									/>

								</Routes>
							</div>
						</MainLayout>
					}
				/>
			</Routes>
		</AuthProvider>
	);
};

export default AppRoutes;