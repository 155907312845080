import React, { useEffect, useState } from 'react';
import { Checkbox, Card, Form, Input, Select, Button, Row, Col, TimePicker, notification} from 'antd';
import axios from 'axios';  
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const DeliveriresCreate: React.FC = () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const url = process.env.REACT_APP_API_URL;
    const navigate = useNavigate(); 
    const [api, contextHolder] = notification.useNotification(); 
    
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const [clientsList, setClientsList] = useState<any>(null);
    const [usersList, setUsersList] = useState<any>(null); 

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/deliveries');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    const onFinish = (values: any) => {
        // Transform the schedules value to a suitable format
        if (values.schedules_start && values.schedules_end) {
            values.schedules_start = values.schedules_start.format('HH:mm');
            values.schedules_end = values.schedules_end.format('HH:mm');
        }
        if (values.stackable === undefined) {
            values.stackable = false;
        }
        if (values.redidential_place === undefined) {
            values.redidential_place = false;
        }
        if (values.lift_dolly === undefined) {
            values.lift_dolly = false;
        }
        if (values.open_daily === undefined) {
            values.open_daily = false;
        }
        if (values.mandatory === undefined) {
            values.mandatory = false;
        }
        if (values.beside_pod === undefined) {
            values.beside_pod = false;
        } 
        createDelivery(values); 
    };

    const getInitValues = async () => { 
        setLoading(true);
        await getClientsList();
        await getUsersList();
        setLoading(false);
    }
    
    useEffect(() => {
        getInitValues();
    }, []);

    const getClientsList = async () => {
        try{
            const response = await axios.get(`${url}/clients` ,
                {
                    headers: {
                        'Content-Type': 'application/json',
                     'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setClientsList(response.data); 
        }catch(error:any){
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
        }
    }

    const getUsersList = async () => {
        try{
            const response = await axios.get(`${url}/users-out-delivery`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setUsersList(response.data); 
        }catch(error:any){
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
        }
    }

    const createDelivery = async (values:any) => {
        try{
            const response = await axios.post(`${url}/deliveries`, values ,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }); 
            form.resetFields();
            api.open({
                message: 'Delivery created successfully',
                description: response.data.message,
                type: 'success',
                duration: 3,
            });
            navigate('/deliveries/details/' + response.data.id); 
        }catch(error:any){
            console.error('Error fetching data:', error);
            api.open({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                type: 'error',
                duration: 3,
            });
            errorRedirecciont(error);
        }
    }




    return (
        <> {contextHolder}
        <div style={{ padding: 24 }}>
            <Card title="Create Delivery" style={{ flex: 3, marginRight: 20 ,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}} loading={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Client"
                                name="client_id" 
                            >
                                {clientsList && (
                                    <Select placeholder="Select a client">
                                        {clientsList.map((client: any) => (
                                            <Option key={client.id} value={client.id}>{client.name}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="Full Name of the company"
                                name="name"
                                rules={[{ required: true, message: 'Please enter the full name of the company' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Zip code and Address of the delivery place"
                                name="zipcode"
                                rules={[{ required: true, message: 'Please enter the zip code and address of the delivery place' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Tax Id"
                                name="tax_id" 
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please enter the phone' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mail"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please enter the email' },
                                    { type: 'email', message: 'Please enter a valid email' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Main Contact Person"
                                name="contact_main" 
                            >
                                <Input />
                            </Form.Item> 
                            <Form.Item
                                label="Contact person at Warehouse (in case exists)"
                                name="contact_warehouse"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Commodity"
                                name="commodity" 
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item
                                label="User"
                                name="user_id" 
                            >
                                {
                                    usersList && (
                                        <Select placeholder="Select a user">
                                            {usersList.map((user: any) => (
                                                <Option key={user.id} value={user.id}>{user.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                            
                            <Form.Item label="Delivery schedules" >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="schedules_start" 
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker format="HH:mm" placeholder="Start Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="schedules_end" 
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker format="HH:mm" placeholder="End Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item
                                label="Is it Stackable"
                                name="stackable"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is this a residential delivery place"
                                name="redidential_place"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is a lift or Dolly required for delivery"
                                name="lift_dolly"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is open daily from Monday to Friday?"
                                name="open_daily"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is it mandatory to do an appointment?"
                                name="mandatory"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Besides the POD, is it necessary to get a different document?"
                                name="beside_pod"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Create Delivery
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>

        </>
    );
};

export default DeliveriresCreate;