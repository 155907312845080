import React, { useEffect, useState } from 'react';
import { json, useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Checkbox, Spin, Alert, Button, Input, Skeleton, Select, notification, Modal, Empty } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import axios from 'axios';
import { set } from 'date-fns';
const { Search } = Input;
const { Option } = Select;

const UserDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [permissionsList, setPermissionsList] = useState<any>(null);
    const [selectedPermissions, setSelectedPermissions] = useState<any>(null);
    const [optionPermissions, setOptionPermissions] = useState<any>([]);
    const [selectedRole, setSelectedRole] = useState<string>();
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const [newName, setNewName] = useState<string>('');
    const [newEmail, setNewEmail] = useState<string>('');
    const [clientsList, setClientsList] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [deliveries, setDeliveries] = useState<any>(null);
    const navigate = useNavigate();

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/users');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`http://localhost:3306/api/users/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            await getPermissionsList();
            await getClientsList();
            await getDeliveries();
            await setUser(response.data.user);
            await setSelectedRole(response.data.user.role.id);
            await setNewName(response.data.user.name);
            await setNewEmail(response.data.user.email);
            await setDeliveries(response.data.delivery);
        } catch (error:any) {
            setError('Failed to fetch user details');
            notification.error({
                message: 'Fetch Failed',
                description: error.response.data.message,
            });
            errorRedirecciont(error); 
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [id]);

    useEffect(() => {
        chargePermissions();
    }, [selectedRole]);

    useEffect(() => {
        if (selectedRole !== user?.role?.id) {
            setSelectedPermissions([]);
        }
    }, [optionPermissions]);

    const getPermissionsList = async () => {
        try{
            const response = await axios.get('http://localhost:3306/api/permissions-list',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log('permissions', response.data);
            await setPermissionsList(response.data);
            await chargePermissions(); 
        }catch (error){
            console.error('permissions:', error);
        }

    }

    const getClientsList = async () => {
        try{
            const response = await axios.get('http://localhost:3306/api/clients',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log('clients', response.data);
            setClientsList(response.data); 
        }catch (error){
            console.error('clients:', error);
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (selectedClient) {
            assingClients('add', selectedClient);
            setIsModalVisible(false);
        } else {
            notification.error({
                message: 'No Client Selected',
                description: 'Please select a client to assign.',
            });
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClientChange = (value: string) => {
        setSelectedClient(value);
    };

    const handleRoleChange = (value: string) => {
        setSelectedRole(value);
        setSelectedPermissions([]);
        chargePermissions();
    };

    const chargePermissions = async () => {
        const selectedRolePermissions = await permissionsList?.find((role: { role_id: string; }) => role.role_id === selectedRole)?.permissions || [];
        const select = await user?.permissions.map((permission: { id: any; }) => permission.id);
        setSelectedPermissions(select);
        await setOptionPermissions(selectedRolePermissions);
        console.log('options permissions', optionPermissions);
    };

    const handlePermissionsChange = (checkedValues: any) => {
        setSelectedPermissions(checkedValues);
    };

    const handleUpdate = async () => {
        console.log('selectedRole', selectedRole);
        console.log('selectedPermissions', selectedPermissions);
        console.log('newName', newName);
        console.log('newEmail', newEmail);
        try {
            const response = await axios.put(`http://localhost:3306/api/users/${id}`,
                {
                    role_id: selectedRole,
                    permissions: selectedPermissions,
                    name: newName,
                    email: newEmail
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            console.log('response', response.data);

            notification.success({
                message: 'User Updated',
                description: `User with ID ${id} has been updated.`,
            });
            fetchUserDetails();
        } catch (error: any) {
            setError('Failed to update user details');
            notification.error({
                message: 'Update Failed',
                description: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const assingClients = async (action: string, client_id: string) => {
        try {
            const response = await axios.post(`http://localhost:3306/api/clients-reference/${id}`,
                {
                    action: action,
                    client_id: client_id
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            console.log('response', response.data);

            notification.success({
                message: 'Client Assigned',
                description: `Client with ID ${id} has been assigned.`,
            });
            fetchUserDetails();
        } catch (error: any) {
            setError('Failed to update Client details');
            notification.error({
                message: 'Update Failed',
                description: error.response.data.message,
            });
        } finally {
            setLoading(false);
        }
    }

    const getDeliveries = async () => {
        try {
            const response = await axios.get('http://localhost:3306/api/deliveries-out-clients',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log('deliveries', response.data);
        } catch (error) {
            console.error('deliveries:', error);
        }
    }



    if (loading) {
        return (<>
            <div style={{ padding: '24px' }}>
                <div style={{ display: 'flex' }}>
                    <Card title="User Details" style={{ flex: 3,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                        <Skeleton></Skeleton>
                    </Card>

                    <Card style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                        <Skeleton></Skeleton>
                    </Card>
                </div>

                <div style={{ display: 'flex' }}>
                    <Card style={{ flex: 1, marginBlock: 10, marginRight: 5,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                        <Skeleton></Skeleton>
                    </Card>
                    <Card style={{ flex: 1, marginBlock: 10, marginLeft: 5,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}}>
                        <Skeleton></Skeleton>
                    </Card>

                </div>

            </div>
        </>);
    }

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ display: 'flex' }}>
                <Card title="User Details" style={{ flex: 3,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Name">
                            <Input variant="borderless" value={newName} onChange={(e) => setNewName(e.target.value)}></Input>
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                            <Input variant="borderless" value={newEmail} onChange={(e) => setNewEmail(e.target.value)}></Input>
                        </Descriptions.Item>
                        <Descriptions.Item label="Role">
                            <Select variant="borderless" value={selectedRole} onChange={handleRoleChange} style={{ width: '100%' }}>
                                {permissionsList?.map((role: any) => (
                                    <Option key={role.role_id} value={role.role_id}>
                                        {role.role_name}
                                    </Option>
                                ))}
                            </Select>
                        </Descriptions.Item>
                    </Descriptions>
                    <Button type='primary' block style={{ marginBlock: 10 }} onClick={handleUpdate}>
                        Update
                    </Button>
                </Card>

                <Card title=' ' style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <Descriptions bordered column={1} layout="vertical">
                        <Descriptions.Item label="Permissions">
                            <Checkbox.Group value={selectedPermissions} onChange={handlePermissionsChange}>
                                {optionPermissions?.map((permission: any) => (
                                    <Checkbox key={permission.id} value={permission.id} style={{ width: '100%' }}>
                                        {permission.name}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>

            <div style={{ display: 'flex' }} >
                <Card style={{ flex: 1, marginBlock: 10, marginRight: 5,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    Clients
                    <div style={{ marginBlock: 10, display: 'flex' }}>
                        <Button block type='primary' style={{ flex: 1, marginRight: 5 }} onClick={showModal}>
                            + Assign Client
                        </Button>

                        <Modal
                            title="Assign Client"
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText="Assign"
                            cancelText="Cancel"
                        >
                            <Select
                                placeholder="Select a client"
                                style={{ width: '100%' }}
                                onChange={handleClientChange}
                            >
                                {clientsList?.map((client: { id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                                    <Option key={client.id} value={client.id}>
                                        {client.name}
                                    </Option>
                                ))}
                            </Select>
                        </Modal>
                        <Search style={{ flex: 2 }}></Search>
                    </div>
                    {user?.clients?.length > 0 ? user?.clients?.map((client: any) => (
                        <Card style={{ marginBlock: 10 }} key={client.id}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 2 }}>
                                    {client.name}
                                </div>
                                <div style={{ flex: 2, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                                    Active
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type='dashed' danger icon={<ApiOutlined />} onClick={() => { assingClients('remove', client.id) }}>
                                        Unssing
                                    </Button>
                                </div>
                            </div>
                        </Card>)) :
                        <>
                            <Empty />
                        </>}
                </Card>
                <Card style={{ flex: 1, marginBlock: 10, marginLeft: 5,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    Deliveries
                    <div style={{ marginBlock: 10, display: 'flex' }}>
                        {/* <Button block type='primary' style={{ flex: 1, marginRight: 10 }}>
                            + Add Delivery
                        </Button> */}
                        <Search style={{ flex: 2 }}></Search>
                    </div>
                    {deliveries?.length > 0 ? deliveries?.map((delivery: any) => (
                        <Card key={delivery.id} style={{ marginBlock: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    {delivery.name}
                                </div>
                                <div style={{ flex: 1, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                                    Active
                                </div>
                            </div>
                        </Card>
                    )) : <>
                        <Empty />
                    </>}

                </Card>

            </div>

        </div>
    );
};

export default UserDetails;