import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Input, Select, Space, Spin, Tooltip } from 'antd';
import type { DatePickerProps, SelectProps } from 'antd';
import { FileExcelTwoTone } from '@ant-design/icons';
import axios from 'axios'; 
import hasPermission from '../../Hooks/hasPermission';
const { Search } = Input; 

interface ReportsFiltersProps {
    datePickerValue: any;
    setDatePickerValue: (value: any) => void;
    selectedCarriers: string[];
    setSelectedCarriers: (value: string[]) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    loading: boolean;
    generateReport: () => void;
    clientList: any;
    deliveriesList: any;
    selecetdClients: string[];
    setSelectedClients: (value: string[]) => void;
    selectedDeliveries: string[];
    setSelectedDeliveries: (value: string[]) => void;

}

interface Carrier {
    name: string;
    id: string;
}

const ReportsFilters: React.FC<ReportsFiltersProps> = ({
    datePickerValue,
    setDatePickerValue,
    selectedCarriers,
    setSelectedCarriers,
    searchValue,
    setSearchValue,
    loading,
    generateReport,
    clientList,
    deliveriesList,
    selecetdClients,
    setSelectedClients,
    selectedDeliveries,
    setSelectedDeliveries
}) => {
    const url = process.env.REACT_APP_API_URL;
    const [carriers, setCarriers] = useState<Carrier[]>([]); 
    const users = localStorage.getItem('users');
    const user = users ? JSON.parse(users)[0] : false;
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0]; 
  
    const canViewLoasAdmin = hasPermission(user?.permissionsActives,'LD_AD_VIEW'); 

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await axios.get(`${url}/carriers`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`
                        }
                    }
                );
                setCarriers(response.data);
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        }; 
        fetchCarriers();
    }, []); 

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (date) {
            setDatePickerValue(date);
        } else {
            setDatePickerValue(null);
        }
    };

    const carrierOptions = carriers.map((carrier: Carrier) => ({
        label: carrier.name,
        value: carrier.id
    }));

    const sharedProps: SelectProps = {
        mode: 'multiple',
        style: { width: '200px' },
        maxTagCount: 'responsive'
    };

    const carrierSelectProps: SelectProps = {
        ...sharedProps,
        options: carrierOptions,
        value: selectedCarriers,
        onChange: setSelectedCarriers,
        placeholder: 'Select Carrier/s'
    }; 

    const clientSelectProps: SelectProps = {
        ...sharedProps,
        options: clientList.map((client: any) => ({ label: client.name, value: client.id })),
        value: selecetdClients,
        onChange: setSelectedClients,
        placeholder: 'Select Client/s'
    };

    const deliverySelectProps: SelectProps = {
        ...sharedProps,
        options: deliveriesList.map((delivery: any) => ({ label: delivery.name, value: delivery.id })),
        value: selectedDeliveries,
        onChange: setSelectedDeliveries,
        placeholder: 'Select Delivery/s'
    };

    return (
        <Spin spinning={loading}>
            <div style={{ marginRight: '25px', marginLeft: '25px' }}>
                <Card style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Space align="baseline" size='large'>
                            <Tooltip placement="top" title={'Generate Report'}>
                                <Button onClick={generateReport} type="primary" icon={<FileExcelTwoTone twoToneColor="#2B9704" />} size={'large'}>
                                    Generate
                                </Button>
                            </Tooltip>
                            <label> <strong> Month: </strong></label>
                            <DatePicker
                                onChange={onChange}
                                picker="month"
                                value={datePickerValue}
                                disabled={loading}
                            />
                            <label> <strong> Carrier/s : </strong></label>
                            <Select {...carrierSelectProps} disabled={loading} /> 
                            {/* PARA SUBIR A PROD */}
                            {
                                canViewLoasAdmin && (
                                    <>
                                        <label> <strong> Client/s : </strong></label>
                                        <Select {...clientSelectProps} disabled={loading} /> 

                                        <label> <strong> Delivery/s : </strong></label>
                                        <Select {...deliverySelectProps} disabled={loading} />
                                    </> 
                                )    
                            } 
                        </Space>
                        <Search
                            placeholder="Search"
                            value={searchValue}
                            disabled={loading}
                            onChange={e => setSearchValue(e.target.value)}
                            onSearch={value => console.log(value)}
                            style={{ minWidth: '100px', width: '100%', maxWidth: '300px' }}
                        />
                    </div>
                </Card>
            </div>
        </Spin>
    );
};

export default ReportsFilters;