import React, { useEffect, useState } from 'react';
import { Card, Form, Input, Select, Button, Checkbox, Alert, Skeleton, notification } from 'antd';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import hasPermission from '../../Hooks/hasPermission';

const { Option } = Select;

const CreateUser: React.FC = () => {
    const url = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm(); 
    const [permissionsList, setPermissionsList] = useState<any>(null);
    const [clientsList, setClientsList] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [permissionOptions, setPermissionOptions] = useState<any>([]);    
    const [api, contextHolder] = notification.useNotification(); 

    const userActive = localStorage.getItem('users');
    const users = userActive ? JSON.parse(userActive)[0] : false;
    const activePermissions = users?.permissionsActives;

    const canEditUsers = hasPermission(activePermissions, 'US_CREATE');
    const canViewUsersAdmin = hasPermission(activePermissions, 'US_AD_VIEW');
    const canViewClients = hasPermission(activePermissions, 'CL_VIEW');
    const canEditClients = hasPermission(activePermissions, 'CL_EDIT');

    const navigate = useNavigate();

    useEffect(() => {
        getInitValues();
    }, []);

    const getInitValues = async () => {
        if (!canEditUsers || !canViewUsersAdmin || !canViewClients || !canEditClients) {
            notification.error({
                message: 'Without permissions',
                description: 'You do not have the necessary permissions to access this module.',
                placement: 'bottomRight',
            });
            navigate('/users');
        } 
        setLoading(true);
        await getPermissionsList();
        await getClientsList();
        setLoading(false);
    }
    
    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/users');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    const getPermissionsList = async () => {
        if (!canViewUsersAdmin) {
            return;
        }
        try{
            const response = await axios.get(`${url}/permissions-list`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setPermissionsList(response.data); 
        }catch(error: any){
            console.error('Error fetching data:', error);
            setError(error.message ? error.message : 'Error fetching data');
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            }); 
        }
    }

    const getClientsList = async () => {
        if (!canViewClients) {
            return;
        }
        try{
            const response = await axios.get(`${url}/clients`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setClientsList(response.data); 
        }catch(error: any){
            console.error('Error fetching data:', error);
            setError(error.message ? error.message : 'Error fetching data');
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
        }
    }

    const handleSubmit = async (values: any) => {
        if (!canEditUsers || !canViewUsersAdmin || !canViewClients || !canEditClients) {
            notification.error({
                message: 'Without permissions',
                description: 'You do not have the necessary permissions to access this module.',
                placement: 'bottomRight',
            });
            navigate('/users');
        }
        try {
            setLoading(true);
            let auxUrl = url?.substring(0, url?.length - 4);
            const response = await axios.post(`${auxUrl}/auth/register`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }); 
            form.resetFields();
            setPermissionOptions([]);
            api.open({
                message: 'User created successfully',
                description: response.data.message,
                type: 'success',
                duration: 3,
            });
            navigate('/user-detail/' + response.data.user.id);
        } catch (error : any) {
            console.error('error', error);
            api.open({
                message: error.response.request.statusText || 'Error',
                description: error.response.data.message || 'Something went wrong',
                type: 'error',
                duration: 3,
            });
            errorRedirecciont(error);
        } finally {
            setLoading(false);
        }
    };

    const changeRole = (value: any) => {
        let options = permissionsList.filter((role: any) => role.role_id === value); 
        setPermissionOptions(options[0].permissions);
        form.resetFields(['permissions'])
        setSelectedRole(value);
    }
    
 

    return (
        <>
        {contextHolder}
        {loading ?
        (<>
            <div style={{ padding: '24px', display: 'flex' }}>
                <Card style={{ flex: 3, marginRight: 20,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <Skeleton active />
                </Card>
                <div style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <Card style={{ width: '100%', marginBottom: 20 }}>
                        <Skeleton active />
                    </Card>
                    <Card style={{ width: '100%',  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                        <Skeleton active />
                    </Card>
                </div>
            </div>
            </>)
         : (
            
        <div style={{ padding: '24px', display: 'flex' }}>  

            <Card title="Create User" style={{ flex: 3, marginRight: 20,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}} loading={loading}>
                <Form form={form} layout="horizontal" onFinish={handleSubmit}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter the name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter the email' },
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please enter the password' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Client"
                        name="clients"
                        rules={[{ required: true, message: 'Please select a client' }]}
                    >
                        <Select placeholder="Select a client"  mode="multiple">
                            {clientsList?.map((client: any) => (
                                <Option key={client.id} value={client.id}>
                                    {client.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Create User
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div style={{ flex: 1 }}>
                <Card   style={{ width: '100%', marginBottom: 20,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}} loading={loading}>
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[{ required: true, message: 'Please select a role' }]}
                        >
                            <Select placeholder="Select Role" onChange={(value) => changeRole(value)} >
                                {permissionsList?.map((role: any) => (
                                    <Option key={role.role_id} value={role.role_id}>
                                        {role.role_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Card>
                <Card style={{ width: '100%',  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    {permissionOptions?.length === 0 ? <Alert message="Please select a role" type="info" /> :
                        <>
                            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                                <Form.Item
                                    label="Permissions"
                                    name="permissions"
                                    rules={[{ required: true, message: 'Please select at least one permission' }]}
                                >
                                    <Checkbox.Group>
                                        {permissionOptions?.map((permission: any) => (
                                            <Checkbox key={permission.id} value={permission.id} style={{ width: '100%' }}>{permission.name}</Checkbox>
                                        ))
                                        }
                                    </Checkbox.Group>
                                </Form.Item>
                            </Form>
                        </>}
                </Card>
            </div>
        </div>)}
        
        </>
    );
};

export default CreateUser;