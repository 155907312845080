import React, { useEffect, useState } from 'react';
import { Select, Input, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import hasPermission from '../../Hooks/hasPermission';

const { Option } = Select;
const { Search } = Input;

interface DeliveriesFiltersProps {
    onSearch: (value: string) => void;
    onCreate: () => void;
}

const DeliveriesFilters: React.FC<DeliveriesFiltersProps> = ({ onSearch, onCreate }) => { 
    const user = JSON.parse(localStorage.getItem('users') || '[]')[0]; 
    const canCreate = hasPermission(user?.permissionsActives, 'DL_CREATE');

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            onSearch(searchTerm);
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }; 

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

            {
                canCreate && (
                    <Button
                        icon={React.createElement(UserAddOutlined)}
                        type="primary"
                        style={{ minWidth: 200, flex: 1 }} 
                        onClick={onCreate}
                    >
                        Create Deliverie
                    </Button>
                )
            } 

            <Search
                placeholder="Search"
                onChange={handleSearchChange}
                style={{ flex: 2, marginInline: 10 }} 
            />
        </div>
    );
};

export default DeliveriesFilters;