import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Form, Input, Select, DatePicker, Button, Space, Alert, InputRef, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

const { Option } = Select;

interface LoadFormProps {
    carriers: Array<{ id: number; name: string }>;
    form: any;
    isLoading: boolean;
    setCarriers: (carriers:any, newCarrier:any) => void;
}

const LoadForm: React.FC<LoadFormProps> = ({ carriers, form, isLoading, setCarriers}) => {
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);
  
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };
  
    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e.preventDefault();
      const newCarrier = { id: name , name: name || `New Carrier ${carriers.length + 1}` };
      setCarriers( carriers, newCarrier);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };
    return (
        <Card title="Load" style={{ margin: '20px', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={isLoading} >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Pro Number" name="proNumber" rules={[{ required: true, message: 'Pro Number is required' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Expected Delivery Date" name="expectedDeliveryDate">
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Origin" name="origin" rules={[{ required: true, message: 'Origin is required' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Destination" name="destination" rules={[{ required: true, message: 'Destination is required' }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Carrier" name="carrier">
                        <Select
                            style={{ width: 300 }}
                            placeholder="Select a carrier"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space style={{ padding: '0 8px 4px' }}>
                                        <Input
                                            placeholder="Please enter carrier"
                                            ref={inputRef}
                                            value={name}
                                            onChange={onNameChange}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                            Add carrier
                                        </Button>
                                    </Space>
                                </>
                            )}
                        >
                            {carriers.map(carrier => (
                                <Option key={carrier.id} value={carrier.id}>
                                    {carrier.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Mode" name="mode">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Billing Reference" name="billingReference">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="PO Number" name="poNumber">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Reference Dylo" name="referenceDylo">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Reference Client" name="referenceClient">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Dispatcher" name="dispatcher">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fullfillment" name="fullfillment">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

const ItemLineForm: React.FC<{ form: any, isLoading: boolean }> = ({ form, isLoading }) => {
    useEffect(() => {
        form.setFieldsValue({ items: [{ name: '', handlingCount: '', handlingType: '', weight: '', weight_units: '', description: '', dimensionL: '', dimensionW: '', dimensionH: '', dimensionUnits: '' }] });
    }, []);
    return (
        <Card title="Item Line" style={{ margin: '20px', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={isLoading}>
            <Form.List name="items" rules={[{
                validator: async (_, items) => {
                    if (!items || items.length === 0) {
                        return Promise.reject(new Error('At least one item is required'));
                    }
                }
            }]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>

                                        <Space style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="center">
                                            <Row>
                                                <Col style={{ width: '50%' }}>
                                                    <Form.Item    {...restField} name={[name, 'name']} fieldKey={[fieldKey ?? key, 'name']} label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ width: '50%', paddingLeft: 20 }}>
                                                    <Form.Item {...restField} name={[name, 'handlingCount']} fieldKey={[fieldKey ?? key, 'handlingCount']} label="Handling Count" rules={[{ required: true, message: 'Handling Count is required' }]}>
                                                        <Input style={{ flex: 1 }} type='number' />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%' }}>
                                                    <Form.Item {...restField} name={[name, 'handlingType']} fieldKey={[fieldKey ?? key, 'handlingType']} label="Handling Type" rules={[{ required: true, message: 'Handling Type is required' }]}>
                                                        <Input />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%', paddingLeft: 20 }}>
                                                    <Form.Item {...restField} name={[name, 'weight']} fieldKey={[fieldKey ?? key, 'weight']} label="Weight" rules={[{ required: true, message: 'Weight is required' }]}>
                                                        <Input type='number' />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%' }}>
                                                    <Form.Item {...restField} name={[name, 'weight_units']} fieldKey={[fieldKey ?? key, 'weight_units']} label="Weight units" rules={[{ required: true, message: 'Weight units are required' }]}>
                                                        <Select>
                                                            <Select.Option value="lbs">lbs</Select.Option>
                                                            <Select.Option value="kgs">kgs</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ width: '50%', paddingLeft: 20 }}>

                                                    <Form.Item {...restField} name={[name, 'description']} fieldKey={[fieldKey ?? key, 'description']} label="Description" rules={[{ message: 'Description is required' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        </Space>

                                    </div>
                                    <div style={{ flex: 1, marginLeft: 20 }}>
                                        <Space style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="center">
                                            <Row >
                                                <Col style={{ width: '50%' }}>
                                                    <Form.Item {...restField} name={[name, 'dimensionL']} fieldKey={[fieldKey ?? key, 'length']} label="Length" rules={[{ required: true, message: 'Length is required' }]}>
                                                        <Input type='number' />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%', paddingLeft: 20 }}>
                                                    <Form.Item {...restField} name={[name, 'dimensionW']} fieldKey={[fieldKey ?? key, 'width']} label="Width" rules={[{ required: true, message: 'Width is required' }]}>
                                                        <Input type='number' />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%' }}>
                                                    <Form.Item {...restField} name={[name, 'dimensionH']} fieldKey={[fieldKey ?? key, 'height']} label="Height" rules={[{ required: true, message: 'Height is required' }]}>
                                                        <Input type='number' />
                                                    </Form.Item>

                                                </Col>
                                                <Col style={{ width: '50%', paddingLeft: 20 }}>
                                                    <Form.Item {...restField} name={[name, 'dimensionUnits']} fieldKey={[fieldKey ?? key, 'dimensionUnits']} label="Dimension Units" rules={[{ required: true, message: 'Weight units are required' }]}>
                                                        <Select>
                                                            <Select.Option value="in">in</Select.Option>
                                                            <Select.Option value="cm">cm</Select.Option>
                                                            <Select.Option value="pulg">pulg</Select.Option>
                                                            <Select.Option value="mts">mts</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Button type="dashed" onClick={() => remove(name)} danger block>
                                                    Remove
                                                </Button>

                                            </Row>
                                        </Space>

                                    </div>

                                </div>
                            </div>
                        ))}
                        {fields.length == 0 && <Alert message="1 Item is required" type="error" style={{ marginBottom: '10px' }} />}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add Item
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

const AccessorialsForm: React.FC<{ form: any, isLoading: boolean }> = ({ form, isLoading }) => {
    return (
        <Card title="Accessorials" style={{ margin: '20px', boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={isLoading}>
            <Form.List name="accessorials">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="center">
                                <Row gutter={16} style={{ width: '100%' }}>
                                    <Col span={8}>
                                        <Form.Item {...restField} name={[name, 'shortName']} fieldKey={[fieldKey ?? key, 'shortName']} label="Short Name">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item {...restField} name={[name, 'fullName']} fieldKey={[fieldKey ?? key, 'fullName']} label="Full Name">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item {...restField} name={[name, 'type']} fieldKey={[fieldKey ?? key, 'type']} label="Type">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button type="dashed" onClick={() => remove(name)} danger>
                                    Remove
                                </Button>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add Accessorial
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

const CreateLoad: React.FC = () => {
    const url = process.env.REACT_APP_API_URL;
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const [carriers, setCarriers] = useState<Array<{ id: number; name: string }>>([]);
    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);
        const fetchCarriers = async () => {
            try {
                const response = await axios.get(`${url}/carriers`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`
                        }
                    }
                );
                setCarriers(response.data);
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };
        fetchCarriers();
        setIsLoading(false);
    }, []);

    const validateForms = async () => {
        try {
            const loadValues = await form.validateFields(['proNumber', 'origin', 'destination']);
            const itemLineValues = await form.validateFields(['items']);

            // Validar que cada atributo de cada ítem no esté vacío
            const items = itemLineValues.items;
            const allItemsValid = items.every((item: { name: any; handlingCount: any; dimensionL: any; dimensionW: any; dimensionH: any; weight: any; description: any; }) =>
                item.name && item.handlingCount && item.dimensionL && item.dimensionW && item.dimensionH && item.weight && item.description
            );

            setIsButtonDisabled(!loadValues || !allItemsValid);
        } catch (error) {
            setIsButtonDisabled(true);
        }
    };

    const handleSubmit = async () => {

        setIsLoading(true);
        try {
            const loadValues = await form.validateFields();
            const data = {
                loadValues
            };
            const response = await axios.post(`${url}/generate-new-load`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setIsLoading(false);

            notification.success({
                message: 'Load created successfully',
                description: `Load ${response.data.newLoad.id} created successfully`,
                showProgress: true,
                pauseOnHover: true,
            });
            navigate(`/guide/detail/${response.data.newLoad.id}`);
        } catch (error: any) {
            console.error('Validation failed:', error);
            notification.error({
                message: error.message ? error.message : 'Error creating load',
                description: error.response?.data?.message ? error.response?.data?.message : 'An error occurred while creating the load',
                placement: 'bottomRight',
            });  
        }

        setIsLoading(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
            <Form form={form} onValuesChange={validateForms} onBlur={validateForms}>
                <LoadForm carriers={carriers} form={form} isLoading={isLoading} setCarriers={(carriers: Array<{ id: number; name: string }>, newCarrier: { id: number; name: string }): void => { setCarriers([ newCarrier, ...carriers]); }}/>
                <ItemLineForm form={form} isLoading={isLoading} />
                <AccessorialsForm form={form} isLoading={isLoading} />
                {isButtonDisabled && <Alert message="Please fill all required fields (*)" type="error" style={{ margin: '20px' }} />}
                <div style={{ margin: '20px' }}>
                    <Button type="primary" htmlType="submit" block onClick={handleSubmit} disabled={isButtonDisabled} >
                        Create new Load
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default CreateLoad;