import React from 'react';
import { notification, Form, Input, Button, Layout, Typography, ConfigProvider } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import { lightTheme, darkTheme } from '../../Layout/theme';
import { useNavigate } from 'react-router-dom'; 


const { Content } = Layout;
const { Title } = Typography;

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
  const currentTheme = isDarkMode ? darkTheme : lightTheme;
  const navigate = useNavigate();

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onFinish = async (values: any) => {
    const { Email, password } = values;

    setIsLoading(true);

    if (!validateEmail(Email)) { 
      notification.warning({
        message: 'Login Failed',
        description: 'Incorrect credentials. Please try again.',
        showProgress: true,
        pauseOnHover: true,
      });
      return;
    }

    try {   
      
      // const response = await axios.post('http://localhost:3306/auth/login', {
      const url = process.env.REACT_APP_API_URL;
      let auxUrl = url?.substring(0, url?.length - 4);

      const response = await axios.post(`${auxUrl}/auth/login`, {
        email: Email,
        password: password,
      });

      // Obtener los valores actuales del local storage
      const currentTokens = JSON.parse(localStorage.getItem('tokens') || '[]');
      const currentUsers = JSON.parse(localStorage.getItem('users') || '[]');

      // Validar que no se encuentren ya en la lista
      const newToken = response.data.token;
      const newUser = response.data.user;

      const userExists = currentUsers.some((user: any) => user.id === newUser.id);

      if (!userExists) {
        currentUsers.push(newUser);
        currentTokens.push(newToken);
      }

      // Guardar los valores actualizados de nuevo en el local storage
      localStorage.setItem('tokens', JSON.stringify(currentTokens));
      localStorage.setItem('users', JSON.stringify(currentUsers));

      notification.success({
        message: 'Login Successful',
        description: `¡Hola ${response.data.user.name}!`,
        showProgress: true,
        pauseOnHover: true,
      });

      setIsLoading(false);
      window.location.href = '/';  

    } catch (error:any) {
      console.error('Error during login:', error);
      notification.warning({
        message: 'Login Failed',
        description: error.response.data.message,
        showProgress: true,
        pauseOnHover: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <ConfigProvider theme={currentTheme}>
      <div style={{ alignContent: 'center', height: '100vh', background: currentTheme.token.colorBgBase }}>
        <Layout style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <Content style={{ maxWidth: '400px', width: '100%', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', background: currentTheme.token.colorBgContainer }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              {isDarkMode ?
                <img src="/Logotipo - Negativo.png" alt="Logo" style={{ width: '100%' }} /> :
                <img src="/Logotipo - Positivo.png" alt="Logo" style={{ width: '100%' }} />
              }
            </div>
            <Title level={2} style={{ textAlign: 'center', color: currentTheme.token.colorTextBase }}>Login</Title>
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="Email"
                rules={[{ required: true, message: 'Please input your Email!' }]}
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: '100%' }}>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </div>
    </ConfigProvider>
  );
};

export default Login;