import React from 'react';
import { Table, Button } from 'antd';
import axios from 'axios';import 
{ EditOutlined } from '@ant-design/icons';


interface User {
    id: string;
    key: string;
    name: string;
    mail: string;
    status: string;
}

interface UserTableProps {
    data: User[];
    onEdit: (key: string) => void;
}

const UserTable: React.FC<UserTableProps> = ({ data, onEdit }) => {
    

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mail',
            dataIndex: 'email',
            key: 'mail',
        },
        {
            title: 'Status',
            dataIndex: 'status_id',
            key: 'status',
        },
        {
            title: 'Loads',
            dataIndex: 'status_id',
            key: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: User) => (
                <Button  color="primary" type="dashed" icon={React.createElement(EditOutlined)} onClick={() => onEdit(record.id)} >Edit</Button>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data} rowKey="key" />;
};

export default UserTable;