import React from 'react';
import { Card, Row, Col, notification} from 'antd';
import DeliveriesTable from './DeliveriesTable';
import DeliveriesFilters from './DeliveriesFilters';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';

const DeliveriesList: React.FC = () => {
    const url = process.env.REACT_APP_API_URL;
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }
    React.useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${url}/deliveries-list`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setUsers(response.data);
            } catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
                errorRedirecciont(error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);
    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card loading={loading} style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}}>
                        <DeliveriesFilters onSearch={function (value: string): void { 
                        }} onCreate={function (): void {
                            navigate('/deliveries/create');
                        }} />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card loading={loading} style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)'}}>
                        <DeliveriesTable data={users} onEdit={function (key: string): void { 
                            navigate(`/deliveries/details/${key}`);
                        }} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DeliveriesList;