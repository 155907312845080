import React, { useEffect, useState } from 'react';
import { Result, Button, Card } from 'antd';
import StatusCardsCounter from './Reports/StatusCardsCounter';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import { Column, Heatmap } from '@ant-design/plots';
import ReactDOM from 'react-dom';

const Home: React.FC = () => {
  const url = process.env.REACT_APP_API_URL;

  const initialStatusData = [
    { status: 'Total', count: 0, color: '', selected: false },
    { status: "Assigned", count: 0, color: '#00a2ae', selected: false },
    { status: 'In Transit', count: 0, color: '#00a2ae', selected: false },
    { status: 'Completed', count: 0, color: '#87d068', selected: false },
    { status: 'Delivered', count: 0, color: '#87d068', selected: false }
  ];
  const [statusData, setStatusData] = useState(initialStatusData);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getStatusCards();
    setLoading(false);
  }, []);

  const tokenExpired = () => {
    notification.warning({
      message: 'Access Failed',
      description: 'Expire or invalid credentials. Login again.',
      showProgress: true,
      pauseOnHover: true,
    });
    localStorage.removeItem('tokens');
    localStorage.removeItem('users');
    navigate('/login');
  };

  const getStatusCards = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    try {
      const response = await axios.get(`${url}/status-loads-cards`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }); 
      const updatedStatusData = statusData.map(item => {
        const matchingStatus = response.data.find((statusItem: any) => statusItem.Estatus === item.status);
        return matchingStatus ? { ...item, count: matchingStatus.count } : { ...item, count: 0 };
      });

      if (updatedStatusData.length > 0) {
        const firstItem = updatedStatusData[0];
        for (let i = 1; i < updatedStatusData.length; i++) {
          firstItem.count += updatedStatusData[i].count;
        }
      }

      setStatusData(updatedStatusData);
    } catch (error: any) {
      if (error.response?.status === 401) {
        tokenExpired();
      } else if (error.response) {
        notification.error({
          message: error.message ? error.message : 'Error fetching data',
          description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
          placement: 'bottomRight',
      });
      }
      console.error('Error fetching status data:', error);
    }
  };
  const handleCardClick = async (status: string) => {
    setStatusData(prevData =>
      prevData.map(item =>
        item.status === status ? { ...item, selected: !item.selected } : item
      )
    );
  };
  const data = [
    { type: '1', value: 2 },
    { type: '2', value: 3 },
    { type: '3', value: 1 },
    { type: '4', value: 5 },
    { type: '5', value: 2 },
    { type: '6', value: 3 },
    { type: '7', value: 1 },
    { type: '8', value: 7 },
    { type: '9', value: 2 },
    { type: '10', value: 3 },
    { type: '11 ', value: 1 },
    { type: '12 ', value: 5 },
    { type: '13 ', value: 2 },
    { type: '14 ', value: 3 },
    { type: '15 ', value: 1 },
    { type: '16 ', value: 7 },
    { type: '17', value: 2 },
    { type: '18', value: 3 },
    { type: '19 ', value: 1 },
    { type: '21 ', value: 5 },
    { type: '22 ', value: 2 },
    { type: '23- ', value: 3 },
    { type: '24 ', value: 1 },
    { type: '25 ', value: 7 },
    { type: '26 ', value: 5 },
    { type: '27 ', value: 2 },
    { type: '28- ', value: 3 },
    { type: '29 ', value: 1 },
    { type: '30 ', value: 7 },
  ];

  const DemoColumn = () => {
    const config = {
      data,
      xField: 'type',
      yField: 'value',
      shapeField: 'column25D',
      style: {
        fill: 'rgba(255, 158, 24, 0.8)',
      },
    };
    return <Column {...config} />;
  };

  const DemoHeatmap = () => {
    const config = {
      autoFit: true,
      data: {
        type: 'fetch',
        value: 'https://assets.antv.antgroup.com/g2/seattle-weather.json',
      },
      xField: (d: any) => new Date(d.date).getUTCDate(),
      yField: (d: any) => new Date(d.date).getUTCMonth(),
      colorField: 'temp_max',
      mark: 'cell',
      style: { inset: 0.5 },
      tooltip: {
        title: 'date',
        field: 'temp_max',
        valueFormatter: '~s',
        pointerEvents: 'none'
      },
    };

    return <Heatmap {...config} />;
  };

  return (
    <>
      {/* <StatusCardsCounter statusData={statusData} handleCardClick={handleCardClick} loading={loading} />
      <div style={{display:'flex', margin:10}}>
      <Card title='Delivered loads' style={{flex:1, marginInline: 10, padding:15}}>
        <DemoHeatmap />
      </Card>
      <Card title='Loads per carrier' style={{flex:1, marginInline: 10,  padding:15}}>
        <DemoColumn />
      </Card>

      </div> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Result
          status="warning"
          title="This page is not available yet"
          subTitle="Sorry, we are working on it."
          extra={
            <Button type="primary" href="/reports">
              Go to Loads
            </Button>
          }
        />
      </div>
    </>
  );
};

export default Home;