import React from 'react';
import { Table, Button } from 'antd';
import axios from 'axios';import 
{ EditOutlined } from '@ant-design/icons';


interface Delivery {
    user: any;
    client: any;
    id: string;
    key: string;
    name: string;
    mail: string;
    status: string;
} 

interface DeliveriesTableProps {
    data: Delivery[];
    onEdit: (key: string) => void;
}

const DeliveriesTable: React.FC<DeliveriesTableProps> = ({ data, onEdit }) => {
    

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mail',
            dataIndex: 'email',
            key: 'mail',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Client',
            dataIndex: 'status_id',
            key: 'status',
            render: (text: any, record: Delivery) => (
                <span>{record.client?.name}</span>
            ),
        },
        {
            title: 'User',
            dataIndex: 'status_id',
            key: 'status',
            render: (text: any, record: Delivery) => (
                <span>{record.user?.name}</span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: Delivery) => (
                <Button  color="primary" type="dashed"  onClick={() => onEdit(record.id)} >Detail</Button>
            ),
        },
    ];

    return <Table columns={columns} dataSource={data}  rowKey="id" />;
};

export default DeliveriesTable;