import React, { useEffect } from 'react';
import { Card, Input, Select, Button, Empty, notification, Modal } from 'antd';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

const ClientDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);
    const [client, setClient] = React.useState<any>(null);
    const [userList, setUsersList] = React.useState<any>(null);
    const [userModal, setUserModal] = React.useState<boolean>(false)
    const [selectUser, setSelectUser] = React.useState<String>('')
    const [api, contextHolder] = notification.useNotification(); 
    const navigate = useNavigate();

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/clients');
        }
        if (error.response?.status === 401) {
            navigate('/login');
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    const fetchClientDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:3306/api/clients/' + id,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setClient(response.data);
            setLoading(false);
        } catch (error:any) {
            setLoading(false);
            setError('Failed to fetch client details');
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
            errorRedirecciont(error); 
        }
    }

    const getUserList = async () => {

        try {
            const response = await axios.get('http://localhost:3306/api/users',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            setUsersList(response.data);

        } catch (error:any) {
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            }); 
        }
    }

    useEffect(() => {
        getUserList();
        fetchClientDetails();
    }, []);

    // if (loading) {
    //     return <>
    //         <div style={{ padding: '24px' }}>
    //             <div style={{ display: 'flex' }}>
    //                 <Card style={{ flex: 1 }} loading >
    //                 </Card>
    //             </div>

    //             <div style={{ display: 'flex', marginTop: 10 }} >
    //                 <Card style={{ flex: 1, }} loading >

    //                 </Card>
    //                 <Card style={{ flex: 1, marginInline: 10 }} loading >
    //                 </Card>
    //                 <Card style={{ flex: 1 }} loading>
    //                 </Card>
    //             </div>

    //         </div>
    //     </>;
    // }

    const stateModalUser = async () => {
        setUserModal(!userModal)
        if (!userModal) { setSelectUser('') }
        else {
            userReferenceChange('add', null)
        }
    }

    const userReferenceChange = async (action: string, selecteduser: string | null) => {
        if (selecteduser != null) {
            setSelectUser(selecteduser)
        }
        try {
            const response = await axios.post('http://localhost:3306/api/user-reference/' + id,
                {
                    action: action,
                    user_id: selectUser
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            ); 
            api.open({
                message: 'User created successfully',
                description: response.data.message,
                type: 'success',
                duration: 3,
            });
            fetchClientDetails();
            setSelectUser('')

        } catch (error: any) {
            console.error(error)
            api.open({
                message: error.response?.request?.statusText || 'Error',
                description: error.response?.data?.message || 'Something went wrong',
                type: 'error',
                duration: 3,
            });
        }
    }

    const handleUserChange = (value: string) => {
        setSelectUser(value);
    }

    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <Modal
                title="Assign User"
                open={userModal}
                onOk={stateModalUser}
                onCancel={stateModalUser}
                okText="Assign"
                cancelText="Cancel"
            >
                <Select
                    placeholder="Select a user"
                    style={{ width: '100%' }}
                    onChange={handleUserChange}
                >
                    {userList?.map((user: any) => (
                        <Option key={user.id} value={user.id}>
                            {user.name}
                        </Option>
                    ))}
                </Select>
            </Modal>
            <div style={{ display: 'flex' }}>
                <Card style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    <h1>  {client?.client?.name}  </h1>
                </Card>
            </div>

            <div style={{ display: 'flex', marginTop: 10 }} >
                <Card style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    LOADS
                    <div style={{ marginBlock: 10, display: 'flex' }}>
                        {/* <Button block type='primary' style={{  flex:1, marginRight:10 }}>
                            + Add Load
                        </Button> */}
                        <Search style={{ flex: 2 }}></Search>
                    </div>

                    {
                        client?.loads?.length > 0 ? client?.loads?.map((load: any) => (
                            <Card key={load.id} style={{ marginBlock: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        {load.name}
                                    </div>
                                    <div style={{ flex: 1, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                                        Active
                                    </div>
                                </div>
                            </Card>
                        )) : (
                            <>
                                <Empty style={{ marginBlock: 20 }} />
                            </>
                        )
                    }
                </Card>
                <Card style={{ flex: 1, marginInline: 10,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    USERS
                    <div style={{ marginBlock: 10, display: 'flex' }}>
                        <Button block type='primary' style={{ flex: 1, marginRight: 10 }} onClick={stateModalUser}>
                            + Add User
                        </Button>
                        <Search style={{ flex: 2 }}></Search>
                    </div>
                    {
                        client?.userClients?.length > 0 ? client?.userClients?.map((user: any) => (
                            <Card key={user.user.id} style={{ marginBlock: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        {user.user.name}
                                    </div>
                                    <div style={{ flex: 1, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                                        Active
                                    </div>

                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type='dashed' danger icon={<ApiOutlined />} onClick={() => { userReferenceChange('remove', user.user.id) }}>
                                            Unssing
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        )) : (
                            <>
                                <Empty style={{ marginBlock: 20 }} />
                            </>
                        )
                    }
                </Card>
                <Card style={{ flex: 1,  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    DELIVERIES
                    <div style={{ marginBlock: 10, display: 'flex' }}>
                        {/* <Button block type='primary' style={{  flex:1, marginRight:10 }}>
                            + Add Delivery
                        </Button> */}
                        <Search style={{ flex: 2 }}></Search>
                    </div>
                    {
                        client?.deliveries?.length > 0 ? client?.deliveries?.map((delivery: any) => (
                            <Card key={delivery.id} style={{ marginBlock: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        {delivery.name}
                                    </div>
                                    <div style={{ flex: 1, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                                        Active
                                    </div>
                                </div>
                            </Card>
                        )) : (
                            <>
                                <Empty style={{ marginBlock: 20 }} />
                            </>
                        )
                    }
                </Card>
            </div>

        </div>
    );
};

export default ClientDetail;