import React, { useState } from 'react';
import { Card, Button, Row, Col, Modal, Form, Input, message, notification } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;

const ClientList: React.FC = () => {
    const [clients, setClients] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/');
        }
        if (error.response?.status === 401) {
            navigate('/login');
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            // Aquí puedes manejar la solicitud a la API para crear un nuevo cliente
            // Simulando una solicitud exitosa
            const response = await axios.post('http://localhost:3306/api/clients', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });


            if (response.data) {
                message.success('Client created successfully');
                fetchUsers();
                setIsModalVisible(false);
                form.resetFields();
                setLoading(false);

            } else {
                message.error('Failed to create client ');
                setLoading(false);
            }
        } catch (error:any) {
            console.error('Validation failed:', error);
            notification.error({
                message: error.message ? error.message : 'Validation failed',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('http://localhost:3306/api/clients',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setClients(response.data);
        } catch (error:any) {
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
            errorRedirecciont(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Card loading={loading} style={{ width: '40%',  boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                    <div style={{ display: 'flex' }}>
                        <Button style={{ flex: 1, marginInline: 10 }} type='primary' block onClick={showModal}>
                            + New Client
                        </Button>
                        <Search style={{ flex: 2, marginInline: 10 }} />
                    </div>
                </Card>
            </div>
            <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
                {clients.map((client: any) => (
                    <Col key={client.id} xs={24} sm={12} md={8} lg={6} xl={6} style={{ maxWidth: 600 }}>
                        <Card loading={loading} style={{ boxShadow: '10px 20px 20px #87d0683A'}}>
                            <h2 style={{ textAlign: 'center' }}>{client.name}</h2>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, textAlign: 'center' }}>

                                    Loads: 0
                                </div>
                                <div style={{ flex: 1, textAlign: 'center', marginInline: 10 }}  >
                                    Clients: 0
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, background: `#87d0681A`, color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>

                                    Active
                                </div>
                                <Button style={{ flex: 1, marginInline: 10 }} onClick={() => { navigate('/client/' + client.id) }}>
                                    Detail
                                </Button>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <div style={{ padding: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                <Modal
                    title="Create New Client"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Create"
                    cancelText="Cancel"
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        </div>
    );
};

export default ClientList;