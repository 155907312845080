

const permissionMap: { [key: string]: string } = {
    'd42a7efa-8268-11ef-ba97-06f8ae44a30f': 'LOADS',
    'd42a81fe-8268-11ef-ba97-06f8ae44a30f': 'LD_VIEW',
    'd42a8306-8268-11ef-ba97-06f8ae44a30f': 'LD_AD_VIEW',
    'd42a8392-8268-11ef-ba97-06f8ae44a30f': 'LD_EDIT',
    'd42a8402-8268-11ef-ba97-06f8ae44a30f': 'LD_AD_EDIT',
    'd42a8470-8268-11ef-ba97-06f8ae44a30f': 'LD_CREATE',
    'd42a84f0-8268-11ef-ba97-06f8ae44a30f': 'USER',
    'd42a857d-8268-11ef-ba97-06f8ae44a30f': 'US_VIEW',
    'd42a85e9-8268-11ef-ba97-06f8ae44a30f': 'US_AD_VIEW',
    'd42a865a-8268-11ef-ba97-06f8ae44a30f': 'US_EDIT',
    'd42a86c9-8268-11ef-ba97-06f8ae44a30f': 'US_AD_EDIT',
    'd42a8736-8268-11ef-ba97-06f8ae44a30f': 'US_CREATE',
    'd42a879f-8268-11ef-ba97-06f8ae44a30f': 'CLIENTS',
    'd42a880b-8268-11ef-ba97-06f8ae44a30f': 'CL_VIEW',
    'd42a887d-8268-11ef-ba97-06f8ae44a30f': 'CL_EDIT',
    'd42a88f2-8268-11ef-ba97-06f8ae44a30f': 'CL_CREATE',
    'd42a8965-8268-11ef-ba97-06f8ae44a30f': 'DELIVERIES',
    'd42a89e4-8268-11ef-ba97-06f8ae44a30f': 'DL_VIEW',
    'd42a8d84-8268-11ef-ba97-06f8ae44a30f': 'DL_EDIT',
    'd42a8e27-8268-11ef-ba97-06f8ae44a30f': 'DL_CREATE',
};

const hasPermission = (userPermissions: { permission_id: string }[], permission: string): boolean => {  
 
    if (!userPermissions || !userPermissions.length) {
        window.location.href = ('/login');
        return false
    }
    const permissionId = Object.keys(permissionMap).find(key => permissionMap[key] === permission); 
    if (!permissionId) {
        return false;
    }
    return userPermissions.some(userPermission => userPermission.permission_id === permissionId);
};

export default hasPermission;