import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Checkbox, Spin, Alert, Result, Button, notification, Input, Form, Select, Row, Col, TimePicker, InputRef, Divider, Space } from 'antd';
import axios from 'axios';
import hasPermission from '../../Hooks/hasPermission';
import { PlusOutlined, ApiOutlined } from '@ant-design/icons';
import { get } from 'jquery';
import moment from 'moment';

const { Option } = Select;

const DeliveryDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const url = process.env.REACT_APP_API_URL;
    const [api, contextHolder] = notification.useNotification();
    const [delivery, setDelivery] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [clientsList, setClientsList] = useState<any>([]);
    const [usersList, setUsersList] = useState<any>([]);
    const [form] = Form.useForm();
    const [commoditys, setCommoditys] = useState<any>([]);
    const [name, setName] = useState<string>('');
    const inputRef = useRef<InputRef>(null);



    const users = localStorage.getItem('users');
    const user = users ? JSON.parse(users)[0] : false;
    const activePermissions = user?.permissionsActives;

    const canViewUsers = hasPermission(activePermissions, 'US_VIEW');
    const canViewClients = hasPermission(activePermissions, 'CL_VIEW');
    const canEditUsers = hasPermission(activePermissions, 'US_EDIT');
    const canEditClients = hasPermission(activePermissions, 'CL_EDIT');
    const canEditDeliveries = hasPermission(activePermissions, 'DL_EDIT');

    const navigate = useNavigate();

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/deliveries');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    useEffect(() => {
        const fetchDeliveryDetails = async () => {
            try {
                const response = await axios.get(`${url}/deliveries/${id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                const data = response.data;
                if (data.shedules_start) {
                    data.shedules_start = moment.utc(data.shedules_start, 'HH:mm').local();
                }
                if (data.shedules_end) {
                    data.shedules_end = moment.utc(data.shedules_end, 'HH:mm').local();
                }
                await setDelivery(data);
                form.setFieldsValue({
                    ...data,
                    // shedules_start: data.shedules_start ? moment(data.shedules_start, 'HH:mm') : null,
                    // shedules_end: data.shedules_end ? moment(data.shedules_end, 'HH:mm') : null,
                });
                if (canViewClients) { await getClientsList(); }
                if (canViewUsers) { await getUsersList(data.user); }
                getCommoditys();
            } catch (error: any) {
                console.error('Error fetching data:', error);
                setError(error.message ? error.message : 'Error fetching data');
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
                errorRedirecciont(error);
            } finally {
                setLoading(false);
            }
        };
        const getClientsList = async () => {
            try {
                const response = await axios.get(`${url}/clients`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setClientsList(response.data);
            } catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
            }
        }

        const getUsersList = async (user: any) => {
            try {
                const response = await axios.get(`${url}/users-out-delivery`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                if (user) {
                    setUsersList([user, ...response.data]);
                } else {
                    setUsersList(response.data);
                }
            } catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
            }
        }


        const getCommoditys = async () => {
            try {
                const response = await axios.get(`${url}/deliveries/commoditys`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setCommoditys(response.data);
            }
            catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
            }
        }

        fetchDeliveryDetails();

    }, [id]);

    const onFinish = async (values: any) => {
        // Transform the shedules value to a suitable format 
        if (!canEditDeliveries) {
            notification.error({
                message: 'You do not have permission to update deliveries',
                description: 'Please contact your administrator',
                placement: 'bottomRight',
            });
            return;
        }

        try {
            const response = await axios.put(`${url}/deliveries/${id}`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            notification.success({
                message: 'Delivery updated successfully',
                description: 'The delivery ' + response.data.name + ' was updated successfully',
                placement: 'bottomRight',
            });
        } catch (error: any) {
            console.error('Error updating delivery:', error);
            notification.error({
                message: error.message ? error.message : 'Error updating delivery',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
        }
    };

    const onNameChange = (event: any) => {
        setName(event.target.value);
    }

    const addItem = () => {
        if (name) {
            setCommoditys([...commoditys, { id: name, name }]);
            setName('');
            inputRef.current?.focus();
        }
    }

    if (loading) {
        return (
            <div style={{ padding: 24 }}>
                < Card loading />
            </div>
        );
    }

    return (
        <div style={{ padding: 24 }}>
            {contextHolder}
            <Form form={form} layout="vertical" onFinish={onFinish} disabled={!canEditDeliveries}>
                <Card title="Delivery detail" style={{ flex: 3, marginRight: 20, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    <Row gutter={16}>
                        <Col span={12}>
                            {canViewClients && (

                                <Form.Item
                                    label="Client"
                                    name="client_id"
                                    initialValue={delivery?.client_id}
                                >
                                    {clientsList && (
                                        <Select placeholder="Select a client"
                                            defaultValue={delivery?.client?.id}
                                            allowClear={true} onClear={() =>
                                                form.setFieldsValue({ client_id: null })}
                                            disabled={!canEditClients} >
                                            {clientsList.map((client: any) => (
                                                <Option key={client.id} value={client.id}>{client.name}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>)

                            }
                            <Row gutter={16}>
                                <Col span={18}>
                                    <Form.Item
                                        label="Full Name of the company"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the full name of the company' }]}
                                    >
                                        <Input onChange={(e) => {
                                            setName(e.target.value);
                                            const scac = e.target.value.split(' ').map(word => word[0]).join('').toUpperCase();
                                            form.setFieldsValue({ scac });
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="SCAC"
                                        name="scac"
                                        rules={[{ required: true, message: 'Please enter the SCAC' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[{ required: true, message: 'Please select the role' }]}
                            >
                                <Select placeholder="Select a role" allowClear={true} onClear={() => form.setFieldsValue({ role: null })}>
                                    <Option value="shipper">Shipper</Option> {/* sender */}
                                    <Option value="consignee">Consignee</Option> {/* receiver */}
                                    <Option value="both">Both</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please enter the phone' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mail"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please enter the email' },
                                    { type: 'email', message: 'Please enter a valid email' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Main Contact Person"
                                name="contact_main"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Contact person at Warehouse (in case exists)"
                                name="contact_warehouse"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Commodity"
                                name="commodity_id"
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select a carrier"
                                    allowClear={true}
                                    onClear={() => form.setFieldsValue({ commodity_id: null })}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 4px' }}>
                                                <Input
                                                    placeholder="Please enter carrier"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem} disabled={!name}>
                                                    Add carrier
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                >
                                    {commoditys.map((commodity: any) => (
                                        <Option key={commodity.id} value={commodity.id}>
                                            {commodity.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {
                                canViewUsers && (
                                    <Form.Item
                                        label="User"
                                        name="user_id"
                                        initialValue={delivery?.user_id}
                                    >
                                        {
                                            usersList && (
                                                <Select placeholder="Select a user"
                                                    defaultValue={delivery?.user?.id}
                                                    allowClear={true} onClear={() =>
                                                        form.setFieldsValue({ user_id: null })}
                                                    disabled={!canEditUsers} >
                                                    {usersList.map((user: any) => (
                                                        <Option key={user.id} value={user.id}>{user.name}</Option>
                                                    ))}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>

                                )
                            }

                            <Form.Item label="Delivery schedules">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="shedules_start"
                                            style={{ marginBottom: 0 }}
                                            initialValue={delivery?.shedules_start ? moment(delivery.shedules_start, 'HH:mm') : null}
                                        >
                                            <TimePicker format="HH:mm" placeholder="Start Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="shedules_end"
                                            style={{ marginBottom: 0 }}
                                            initialValue={delivery?.shedules_end ? moment(delivery.shedules_end, 'HH:mm') : null}
                                        >
                                            <TimePicker format="HH:mm" placeholder="End Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            {/* {delivery?.shedules_start} */}
                            {/* {delivery?.shedules_end && moment(delivery.shedules_end).format('HH:mm')} */}
                            <Form.Item
                                label="Is it Stackable"
                                name="stackable"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is this a residential delivery place"
                                name="redidential_place"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is a lift or Dolly required for delivery"
                                name="lift_dolly"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is open daily from Monday to Friday?"
                                name="open_daily"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is it mandatory to do an appointment?"
                                name="mandatory"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Besides the POD, is it necessary to get a different document?"
                                name="beside_pod"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Delivery Address" style={{ flex: 3, marginTop: 20, marginRight: 20, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Street 1"
                                name="street_one"
                                rules={[{ required: true, message: 'Please enter the address of the delivery place' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Street 2"
                                name="street_two"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Local Number"
                                name="local_number"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Colony"
                                name="colony"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Zip code"
                                name="zipcode"
                                rules={[{ required: true, message: 'Please enter the zip code delivery place' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="City"
                                name="city"
                                rules={[{ required: true, message: 'Please enter the city' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="State"
                                name="state"
                                rules={[{ required: true, message: 'Please enter the state' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block disabled={!canEditDeliveries}>
                            Update Delivery
                        </Button>
                    </Form.Item>
                </Card>
            </Form>
        </div>
    );
};

export default DeliveryDetails;