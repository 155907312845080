import React, { useEffect, useState } from 'react';
import { Dropdown, Space, Layout, Menu, Card, Collapse, ConfigProvider } from 'antd';
import {
  FileSearchOutlined,
  PieChartOutlined,
  FileAddOutlined,
  UserOutlined,
  SolutionOutlined,
  TruckOutlined,
  DownOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { lightTheme, darkTheme } from './theme';
import { Link, useLocation } from 'react-router-dom'; 
import hasPermission from '../Hooks/hasPermission';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number] & { label: React.ReactNode };

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

interface AppSiderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const AppSider: React.FC<AppSiderProps> = ({ collapsed, setCollapsed }) => { 
  const location = useLocation();
  const currentPath = location.pathname;
  const [isDarkMode, setIsDarkMode] = useState(JSON.parse(localStorage.getItem('theme') || 'false'));
  const users = localStorage.getItem('users');
  const user = users ? JSON.parse(users)[0] : false; 

  const canViewLoas = hasPermission(user?.permissionsActives,'LD_VIEW');
  const canCreateLoas = hasPermission(user?.permissionsActives,'LD_CREATE'); 
  const canViewUsers = hasPermission(user?.permissionsActives,'US_VIEW'); 
  const canViewClients = hasPermission(user?.permissionsActives,'CL_VIEW'); 
  const canViewDeliveries = hasPermission(user?.permissionsActives,'DL_VIEW'); 

  const [items, setItems] = useState<MenuItem[]>([
    getItem(<Link to="/">Dashboard</Link>, '1', <PieChartOutlined />),
  ]);

  const defaultSelectedKey = items.find(item => {
    const link = item?.label as React.ReactElement;
    return link.props.to === currentPath;
  })?.key;

  useEffect(() => {
    if (canViewLoas) {
      setItems(prevItems => [
        ...prevItems,
        getItem(<Link to="/reports">Loads</Link>, '2', <FileSearchOutlined />),
      ]);
    }
    if (canCreateLoas) {
      setItems(prevItems => [
        ...prevItems,
        getItem(<Link to="/new-load">Generate Load</Link>, '3', <FileAddOutlined />),
      ]);
    }
    if (canViewUsers) {
      setItems(prevItems => [
        ...prevItems,
        getItem(<Link to="/users">Users</Link>, '4', <UserOutlined />),
      ]);
    }
    if (canViewClients) {
      setItems(prevItems => [
        ...prevItems,
        getItem(<Link to="/clients">Clients</Link>, '5', <SolutionOutlined />)  ,
      ]);
    } 
    if (canViewDeliveries) {
      setItems(prevItems => [
        ...prevItems,
        getItem(<Link to="/deliveries">Deliveries</Link>, '6', <TruckOutlined />),
      ]);
    } 
    const storedTheme = JSON.parse(localStorage.getItem('theme') || 'false');
    setIsDarkMode(storedTheme);
  }, []);

  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ConfigProvider theme={currentTheme}>
      <Sider
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ background: currentTheme.token.colorBgContainer }}
      >
        {collapsed ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              <img alt="Dylo Express Logo" src="/favicon.png" style={{ maxWidth: '70px', maxHeight: '50px' }} />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              {isDarkMode ?
                <img src="/Logotipo - Negativo.png" alt="Logo" style={{ width: '100%' }} /> :
                <img src="/Logotipo - Positivo.png" alt="Logo" style={{ width: '100%' }} />
              }
            </div>
          </div>
        )}
        <Menu
          defaultSelectedKeys={[String(defaultSelectedKey) || '2']}
          mode="inline"
          items={items}
          style={{ background: currentTheme.token.colorBgContainer }}
        />
      </Sider>
    </ConfigProvider>
  );
};

export default AppSider;